var ajaxRemovalActive = false;
var inMobile = false;
var mobileCartWidth = 1400;

(function ($) {
  $(document).ready(function () {
    if ($(window).innerWidth() < mobileCartWidth) {
      // $(".offcanvas-cart-preference").addClass("d-none");
      // $(".offcanvas-checkout-preference").removeClass("d-none");
      inMobile = true;
    } else {
      // $(".offcanvas-cart-preference").removeClass("d-none");
      // $(".offcanvas-checkout-preference").addClass("d-none");
      $(".offcanvas-cart")
        .removeClass("offcanvas-bottom")
        .addClass("offcanvas-end")
        .css({
          "height": "100%",
          "border": "none",
          "border-top-left-radius": ".5rem",
          "border-top-right-radius": "0",
          "border-bottom-left-radius": ".5rem",
          "width": "600px",
        });
    }

    //header data pool
    let data = JSON.parse($('script[type="text/json"]#auto-load-data').text());
    data["cart-content"].forEach((item) => {
      // $("#offer-box-title").css("display", "none"); // dum
      addProductBar(item);
    });

    updateQuote(data["cart-total"]["amt"]);

    document.addEventListener("updateProductBarsEvent", function (e) {
      updateProductBars(e.detail); // Use the passed data
    });

    document.addEventListener("updateQuoteEvent", function (e) {
      updateQuote(e.detail); // Use the passed data
    });

    $(window).on("resize", () => {
      if ($(window).innerWidth() < mobileCartWidth) {
        inMobile = true;
        // $(".offcanvas-cart-preference").addClass("d-none");
        // $(".offcanvas-checkout-preference").removeClass("d-none");
        $(".offcanvas-cart")
          .removeClass("offcanvas-end")
          .addClass("offcanvas-bottom")
          .css({
            "height": "90%",
            "border-top-left-radius": ".5rem",
            "border-top-right-radius": ".5rem",
            "border-bottom-left-radius": "0",
            "width": "auto",
          });
        // moveToMobileCart();
      } else {
        // $(".offcanvas-cart-preference").removeClass("d-none");
        // $(".offcanvas-checkout-preference").addClass("d-none");
        $(".offcanvas-cart")
          .removeClass("offcanvas-bottom")
          .addClass("offcanvas-end")
          .css({
            "height": "100%",
            "border": "none",
            "border-top-left-radius": ".5rem",
            "border-top-right-radius": "0",
            "border-bottom-left-radius": ".5rem",
            "width": "600px",
          });
        // moveToDesktopCart();
        inMobile = false;
      }
      // updateProductBars(null, inMobile);
    });

    $(document).on("click", ".cart-offcanvas-button", function () {
      $(document).trigger("close-all");
    });

    $(document).on("click", ".cart-toggle-mobile", function () {
      $(document).trigger("close-all");
    });

    $(document).on("click", ".tradein-remove-button", function () {
      let itemNode = $(this).parent().parent().parent();
      removeItemFromCart(itemNode);
    });

    $(document).on("click", ".remove-button", function () {
      let itemNode = $(this).parent().parent().parent().parent();
      removeItemFromCart(itemNode);
    });

    $(document).on("click", ".tradein-quantity-remove", function () {
      let nodeParent = $(this).parent().parent().parent().parent();
      changeItemQuantity(nodeParent, -1);
    });

    $(document).on("click", ".tradein-quantity-add", function () {
      let nodeParent = $(this).parent().parent().parent().parent();
      changeItemQuantity(nodeParent, 1);
    });
  });

  // function moveToMobileCart() {
  //   (function ($) {
  //     let mobileTarget = $(".offcanvas-cart-content-wrapper");
  //     let desktopTarget = $("#tradein-offer-box");

  //     let mobileTemplate = $(".tradein-form-trade-item-template-mobile");

  //     if (desktopTarget.length > 0) {
  //       $(".tradein-form-trade-item").each(function () {
  //         let newMobileBar = $(mobileTemplate)
  //           .clone()
  //           .removeClass("tradein-form-trade-item-template-mobile");
  //         $(newMobileBar)
  //           .find(".offer")
  //           .text(
  //             `$${(
  //               Number($(this).attr("prev-price")) /
  //               Number($(this).find(".quantity").text())
  //             ).toLocaleString()}`
  //           );
  //         $(newMobileBar)
  //           .find(".quantity")
  //           .text(`${$(this).find(".quantity").text()}`);
  //         $(newMobileBar)
  //           .find(".title")
  //           .text($(this).find(".tradein-form-trade-item-description").text());
  //         $(newMobileBar)
  //           .find(".image")
  //           .attr(
  //             "src",
  //             $(this).find(".tradein-form-trade-item-icon-svg").attr("src")
  //           );
  //         $(newMobileBar)
  //           .find(".attributes-wrapper")
  //           .html(
  //             $(this)
  //               .find(".badge-text") // Correct selector for class
  //               .map(function () {
  //                 return `<div style="color: #212121;">${$(this).text()}</div>`;
  //               })
  //               .get() // Convert the jQuery object to an array of HTML strings
  //               .join("") // Join the array into a single string
  //           );

  //         $(newMobileBar).attr("item-key", $(this).attr("item-key"));
  //         $(newMobileBar).attr("prev-price", $(this).attr("prev-price"));

  //         $(newMobileBar).appendTo(mobileTarget).removeClass("d-none");

  //         $(this).remove();
  //       });
  //     }
  //   })(jQuery);
  // }

  // function moveToDesktopCart() {
  //   (function ($) {
  //     let mobileTarget = $(".offcanvas-cart-content-wrapper");
  //     let desktopTarget = $("#tradein-offer-box");

  //     // $("#CartOffcanvas").offcanvas("hide");
  //     if ($(mobileTarget).children().length > 1) {
  //       //Template is +1 item
  //       //Reverse to maintain order

  //       let desktopCloneTarget = $(".tradein-form-trade-item-template");
  //       let cloner = desktopCloneTarget;

  //       $(mobileTarget)
  //         .children()
  //         .not(
  //           ".tradein-form-trade-item-template-mobile, .tradein-form-trade-item-template"
  //         )
  //         .each(function () {
  //           let newDesktopBar = $(cloner).clone();

  //           $(newDesktopBar).removeClass("tradein-form-trade-item-template");
  //           $(newDesktopBar).addClass("tradein-form-trade-item");
  //           $(newDesktopBar).attr("item-key", $(this).attr("item-key"));
  //           $(newDesktopBar).attr("prev-price", $(this).attr("prev-price"));
  //           $(newDesktopBar)
  //             .find(".tradein-form-trade-item-quote-amount")
  //             .text(`$${Number($(this).attr("prev-price")).toLocaleString()}`);
  //           $(newDesktopBar)
  //             .find(".tradein-form-trade-item-description")
  //             .text($(this).find(".title").text());
  //           $(newDesktopBar)
  //             .find(".quantity")
  //             .text($(this).find(".quantity").text());
  //           $(newDesktopBar)
  //             .find(".tradein-form-trade-item-icon-svg")
  //             .attr("src", $(this).find(".image").attr("src"));

  //           $(this)
  //             .find(".attributes-wrapper")
  //             .children()
  //             .each(function () {
  //               let newBadge = $(newDesktopBar).find(".badge-template").clone();
  //               $(newBadge).removeClass("badge-template");
  //               $(newBadge).find(".badge-text").html($(this).text());
  //               $(newBadge).appendTo($(newDesktopBar).find(".badge-wrapper"));
  //             });

  //           $(newDesktopBar).prependTo(desktopTarget).css("opacity", 1);

  //           if ($(this).attr("prev-price") != 0) {
  //             $(newDesktopBar).find(".price-zero-header").remove();
  //           }

  //           if ($(this).find(".quantity").text() < 5) {
  //             $(newDesktopBar).find(".quantity-over-header").hide();
  //           } else {
  //             $(newDesktopBar).find(".quantity-over-header").show();
  //           }

  //           $(this).remove();
  //         });
  //     }
  //   })(jQuery);
  // }

  function changeItemQuantity(node, change) {
    let itemKey = $(node).attr("item-key");
    var ajaxData = {
      action: "tradein_quantity_change",
      nonce: $("#df-ajax-nonce").val(),
      item_key: itemKey,
      quantity_change: change,
    };

    if (ajaxRemovalActive == true) {
      //Multi-request prevention
      return;
    }

    ajaxRemovalActive = true;

    $(node).find(".load-modal").addClass("visible");

    $.ajax({
      type: "post",
      url: $("#df-ajax-url").val(),
      data: ajaxData,
      success: function (response) {
        if (response.error) {
          console.log("ERROR! " + response.error);
        } else {
          $(".cart-desc").replaceWith(response["fragments"]["a.cart-desc"]);
          $(".cart-desc-offcanvas").replaceWith(
            response["fragments"]["button.cart-desc-offcanvas"]
          );

          updateQuote(response["fragments"]["total"]);

          updateProductBars(response["fragments"]["cart-content"], inMobile);
        }
        ajaxRemovalActive = false;
      },
      complete: function () {
        ajaxRemovalActive = false;
        $(`[item-key='${itemKey}']`).find(".load-modal").removeClass("visible");
      },
    });
  }

  function updateQuote(totalQuote) {
    (function ($) {
      let quote = Number(totalQuote).toLocaleString();

      $(".offcanvas-cart-title").text(`Your offer: $${quote}`);

      $(".offer-number").text(`$${quote}`);

      $(".bag-offer-title").text(`$${quote} offer`);
    })(jQuery);
  }

  function removeItemFromCart(itemBar) {
    let itemKey = itemBar.attr("item-key");

    var ajaxData = {
      action: "product_remove",
      nonce: $("#df-ajax-nonce").val(),
      item_key: itemKey,
    };

    if (ajaxRemovalActive == true) {
      //Multi-request prevention
      return;
    }

    ajaxRemovalActive = true;

    $(itemBar).find(".load-modal").addClass("visible"); //desktop
    $(itemBar).find(".load-overlay").removeClass("d-none"); //mobile

    $.ajax({
      type: "post",
      url: $("#df-ajax-url").val(),
      data: ajaxData,
      success: function (response) {
        if (response.error) {
          console.log("Error removing item from cart: " + response.error);
        } else {
          $(".cart-desc").replaceWith(response["fragments"]["a.cart-desc"]);
          $(".cart-desc-offcanvas").replaceWith(
            response["fragments"]["button.cart-desc-offcanvas"]
          );

          updateProductBars(response["fragments"]["cart-content"], inMobile);
          updateQuote(response["total"]);
        }
        ajaxRemovalActive = false;
      },
      complete: function () {
        ajaxRemovalActive = false;
        itemBar.find(".load-modal").removeClass("visible");
        $(itemBar).find(".load-overlay").addClass("d-none"); //mobile
      },
    });
  }
})(jQuery);

//If in mobile, take items from desktop and move to mobile
export function updateProductBars(items) {
  (function ($) {
    //Null on pre-ajax load
    if (items != null) {
      $(".offcanvas-cart-content-wrapper")
        .children()
        .not(
          ".tradein-form-trade-item-template-mobile, .tradein-form-trade-item-template"
        )
        .each(function () {
          let safe = false;

          for (let i = 0; i < items.length; i++) {
            if (items[i]["key"] == $(this).attr("item-key")) {
              safe = true;
            }
          }

          //Remove items that are no longer in the cart
          if (safe == false) {
            $(this).remove();
          }
        });
    }

    if (items == null) {
      return; // Update-only function guard statement
    }

    let totalQuote = 0;

    //Loop through items and update quantity or create new item if not in the cart
    for (let i = 0; i < items.length; i++) {
      //Check if item already exists
      let itemSelector = $("[item-key='" + items[i]["key"] + "']");

      totalQuote +=
        parseInt(items[i]["quantity"]) * parseInt(items[i]["price"]);

      //If item exists, update quantity and price
      if ($(itemSelector).length > 0) {
        let thisItem = itemSelector[0];

        let newQuantity = parseInt(items[i]["quantity"]);
        let newPrice = parseInt(items[i]["price"]);

        if (newQuantity < 5) {
          $(thisItem).find(".quantity-over-header").hide();
        } else {
          $(thisItem).find(".quantity-over-header").show();
        }

        // if (
        //   $(thisItem).attr("prev-price") != newQuantity * newPrice ||
        //   $(thisItem).attr("prev-price") == null
        // ) {
        //   //Animate all bars
        //   $({ Counter: $(thisItem).attr("prev-price") }).animate(
        //     { Counter: newQuantity * newPrice },
        //     {
        //       duration: 1000,
        //       easing: "swing",
        //       step: function () {
        //         $(thisItem)
        //           .find(".tradein-form-trade-item-quote-amount")
        //           .text(`$${Math.round(this.Counter).toLocaleString()}`);
        //       },
        //       complete: function () {
        //         $(
        //           $(thisItem).find(".tradein-form-trade-item-quote-amount")
        //         ).text(`$${Number(newQuantity * newPrice).toLocaleString()}`);
        //       },
        //     }
        //   );
        // }
        // $(thisItem).attr("prev-price", newQuantity * newPrice);

        $(thisItem).find(".quantity").html(items[i]["quantity"]);
      } else {
        // Create new item
        addProductBar(items[i]);
      }
    }

    //Check if no items then display message
    if (items != null) {
      if ($(".tradein-form-trade-item").length == 0) {
        $("#cta-cart-title").text(
          "You don't have any items in your offer list."
        );
        $(".cta-cart-button").css("display", "flex");

        $("#offer-box-title-header").text("Your offer list will appear here.");

        $("#offer-box-title-subheader").text(
          "Add items with the form to get started."
        );
        $("#offer-box-title").css("display", "flex");

        $(".offcanvas-cart-cta").css("display", "block");
        $(".offcanvas-cart-button").css("display", "none");
      } else {
        $("#offer-box-title").css("display", "none");
        $(".offcanvas-cart-button").css("display", "block");
        $(".offcanvas-cart-cta").css("display", "none");
      }
    }

    if ($(".tradein-form-trade-item").length == 0) {
      $(".offer-box-actions").css("height", "0px");
    } else {
      // $("#offer-action-text").text(
      //   `Great! Ready to cash in your $${totalQuote.toLocaleString()} offer?`
      // );
      // $("#offer-action-text").html(
      //   `<div class='fs-5'> Great! Ready to cash in on your $${totalQuote.toLocaleString()} offer?</div><div class='fs-6'>Lock in your offer for 14 days & free shipping</div>`
      // );
      // $(".offer-box-actions").css("height", "75px");
    }
  })(jQuery);

  return false;
}

function addProductBar(newItem) {
  (function ($) {
    let mobileTarget = $(".offcanvas-cart-content-wrapper");
    // let desktopTarget = $("#tradein-offer-box");

    let prettyName = newItem["pretty name"];
    let badgeAttributes = newItem["attributes"];
    let typeImageUrl = newItem["image"];
    let quantity = parseInt(newItem["quantity"]);
    let price = parseInt(newItem["price"]);
    let itemKey = newItem["key"];

    let newMobileBar = $(".tradein-form-trade-item-template-mobile")
      .clone()
      .removeClass("tradein-form-trade-item-template-mobile");
    $(newMobileBar).find(".offer").text(`$${price.toLocaleString()}`);
    $(newMobileBar).find(".quantity").text(quantity);
    $(newMobileBar).find(".title").text(prettyName);
    $(newMobileBar).find(".image").attr("src", typeImageUrl);
    let attributesHtml = badgeAttributes
      .map((attribute) => `<div style="color: #212121;">${attribute}</div>`)
      .join("");
    $(newMobileBar).find(".attributes-wrapper").html(attributesHtml);

    $(newMobileBar).attr("item-key", itemKey);
    // $(newMobileBar).attr("prev-price", $(this).attr("prev-price"));

    //Add to top so it shows up first
    $(newMobileBar).prependTo(mobileTarget).removeClass("d-none");

    // if (inMobile == true) {
    //   console.log("Mobile: " + newItem["key"]);
    //   let newMobileBar = $(".tradein-form-trade-item-template-mobile").clone();
    //   $(newMobileBar)
    //     .find(".offer")
    //     .text(
    //       `$${(
    //         Number($(this).attr("prev-price")) /
    //         Number($(this).find(".quantity").text())
    //       ).toLocaleString()}`
    //     );
    //   $(newMobileBar)
    //     .find(".quantity")
    //     .text(`Qty: ${$(this).find(".quantity").text()}`);
    //   $(newMobileBar)
    //     .find(".title")
    //     .text($(this).find(".tradein-form-trade-item-description").text());
    //   $(newMobileBar)
    //     .find(".image")
    //     .attr(
    //       "src",
    //       $(this).find(".tradein-form-trade-item-icon-svg").attr("src")
    //     );
    //   $(newMobileBar)
    //     .find(".attributes-wrapper")
    //     .html(
    //       $(this)
    //         .find(".badge-text") // Correct selector for class
    //         .map(function () {
    //           return `<div style="color: #212121;">${$(this).text()}</div>`;
    //         })
    //         .get() // Convert the jQuery object to an array of HTML strings
    //         .join("") // Join the array into a single string
    //     );

    //   $(newMobileBar).attr("item-key", $(this).attr("item-key"));
    //   $(newMobileBar).attr("prev-price", $(this).attr("prev-price"));

    //   $(newMobileBar).appendTo(mobileTarget).removeClass("d-none");
    //   $(this).remove();

    //   $(newMobileBar).prependTo(mobileTarget);
    // } else {
    //   console.log("Desktop: " + newItem["key"]);
    //   let cloneTarget = $(".tradein-form-trade-item-template");
    //   let cloner = cloneTarget;
    //   let newBar = $(cloner)
    //     .clone()
    //     .removeClass("tradein-form-trade-item-template")
    //     .addClass("tradein-form-trade-item")
    //     .show(); // show in case display none

    //   let newBarDesc = $(newBar).find(".tradein-form-trade-item-description");
    //   let newBarQuantity = $(newBar).find(".quantity");
    //   let newBarBadgeWrapper = $(newBar).find(".badge-wrapper");
    //   let newBarBadge = $(newBar).find(".badge-template");

    //   for (let i = 0; i < badgeAttributes.length; i++) {
    //     let newBadge = $(newBarBadge).clone().removeClass("badge-template");
    //     $(newBadge).find(".badge-text").html(badgeAttributes[i]);
    //     $(newBadge).appendTo(newBarBadgeWrapper);
    //   }

    //   $(newBarBadge).remove();

    //   $(newBar).attr("item-key", newItem["key"]);
    //   $(newBar).attr("prev-price", quantity * price);
    //   $(newBarDesc).html(`${prettyName}`);
    //   $(newBarQuantity).html(quantity);
    //   $(newBar)
    //     .find(".tradein-form-trade-item-icon-svg")
    //     .attr("src", typeImageUrl);

    //   if (price != 0) {
    //     $(newBar).find(".price-zero-header").remove();
    //   }

    //   if (quantity < 5) {
    //     $(newBar).find(".quantity-over-header").hide();
    //   } else {
    //     $(newBar).find(".quantity-over-header").show();
    //   }

    //   $(newBar)
    //     .find(".tradein-form-trade-item-quote-amount")
    //     .html(`$${Number(quantity * price).toLocaleString()}`);

    //   //Desktop
    //   $(newBar).appendTo(desktopTarget);

    //   setTimeout(function () {
    //     newBar.css({
    //       transform: "translateY(0)", // Move to final position
    //       opacity: 1, // Fade to full opacity
    //     });
    //   }, 100);
    // }
  })(jQuery);
}
